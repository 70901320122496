import axios from 'axios'
import config from './config'
import {OAuth} from "./oauth";
import {applyInterceptors} from "@ibb/ui-components/lib/api/interceptor";
import { createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';

///----------------------------------------------------------------------------
/// API CONFIG
///----------------------------------------------------------------------------

const api = createApi();
const apiNoToken = createApi2();

function createApi() {
  const api = axios.create({
    baseURL: config.API_URL
  });
  return applyInterceptors(api, {
    oauth: OAuth,
    // inProgress: IN_PROGRESS_SERVICE.getHandler(),
    errorHandler: alertErrorMessage,
  })
}

function createApi2() {
  const api = axios.create({
    baseURL: config.API_URL
  });
  return api
}

function alertErrorMessage(errorData) {
  if (typeof errorData === 'string') {
    Swal.fire({
      title: "Hata",
      text: errorData,
      icon: 'error',
      confirmButtonText: 'Tamam'
    })
    return;
  } else {
    const {error, message, path, status} = errorData;
    if (error && message) {
      Swal.fire({
        title: status + " " + error,
        html: `<span class='fw-bold'>Error: </span>${message} </br> <span class="fw-bold">Path:</span> ${path}`,
        icon: 'error',
        confirmButtonText: 'Tamam'
      })
      return;
    }
  }
  alert('HATA: Beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
}

// export const getSektorList = createAsyncThunk('sektorList', async () => {
//   const { data } = await api.get(`sektor/sektorList`);
//   return data;
// })

// const getMeslek = () => api.get('meslek/meslekList')

// const makeAsyncThunk =  (thunkName, http) =>{
//   return createAsyncThunk(thunkName, async()=>{
//     const {data} = await http()
//     return data
//   })
// }

// export const getMeslekList = makeAsyncThunk("meslekList", getMeslek)

// function errorMessage(errorData) {
//   const {message, errorCode} = errorData.apierror;
//   if(errorCode === "21005"){
//     alert(message)
//     window.location.href='/yapilandirma';
//   }else if(errorCode === "11002"){
//     alert(message)
//   }else if(errorCode === null){
//   }else{
//     alert(message)
//   }
// }

// function handleApiResponse(res, converter) {
//   if (res && res.data) {
//     const data = res.data;
//     const result = converter ? converter(data) : data;
//     const islemSonuc = data ? data.islemSonuc : null;

//     if (islemSonuc && islemSonuc.kod !== '90000' && islemSonuc.aciklama) {
//       return Promise.reject(islemSonuc.aciklama);
//     } else {
//       return Promise.resolve(result)
//     }
//   } else {
//     return Promise.reject(res.statusText);
//   }
// }

///----------------------------------------------------------------------------
/// API METHODS
///----------------------------------------------------------------------------

export {
  api,
  apiNoToken
}