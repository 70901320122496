import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import logo from '../../assets/images/home/bütçelogo.png';
import {OAuth} from '../../services/oauth';
import girisIcon from '../../assets/images/home/Group 121.png';
import {useSelector} from 'react-redux';
import {Badge, Dropdown} from 'react-bootstrap';
import cartImg from '../../assets/images/standart/Cart.svg';
import {apiNoToken} from '../../services/api';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {getDate} from '../../services/utils';

export default function Header() {
  const [navbar, setNavbar] = useState(false);
  const [aktifGun, setAktifGun] = useState('');

  const {user} = useSelector((state) => state.user);

  const queryString = new URLSearchParams(window.location.href);
  const [code, setCode] = useState(queryString.get('code'));

  const history = useHistory();

  const handleIstanbulLogin = () => {
    apiNoToken.get(`SuperAppLogin/web-oidc?redirect_uri=${process.env.REACT_APP_IST_SENIN_REDIRECT_URI}`).then((res) => {
      window.location.href = res.data.data.composedUrl;
    });
  };

  useEffect(() => {
    const numberOfDay = getDate();
    setAktifGun(numberOfDay);
  }, []);

  useEffect(() => {
    if (code) {
      apiNoToken
        .post('SuperAppLogin/web-code', {
          code,
          redirectUri: process.env.REACT_APP_IST_SENIN_REDIRECT_URI,
        })
        .then((res) => {
          history.push('/giris');
          OAuth._store.init(res.data.data.token, res.data.data.idToken);
        });
    }
  }, [code]);

  const logOut = () => {
    //window.location.href = `${process.env.REACT_APP_IST_SENIN_LOGOUT}?redirect_uri=${process.env.REACT_APP_IST_SENIN_REDIRECT_URI}`
    OAuth.logout();
    localStorage.clear();
  };

  const downloadFile = () => {
    setNavbar(false);
    const link = document.createElement('a');
    link.href = 'https://www.docdroid.net/ZPsHsZM/butce-senin-karar-senin-pdf';
    link.target = '_blank';
    link.download = 'https://www.docdroid.net/ZPsHsZM/butce-senin-karar-senin-pdf';

    // Simulate a click on the element <a>
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {/*--Header Top--*/}
      {/* <div className="header-top-wrapper">
        <div className="header-top container">
          <div className="header-top-icons">
            <Link to="/" className="mx-2">
              <img src={facebook} alt="facebook" />
            </Link>
            <Link to="/" className="mx-2">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link to="/" className="mx-2">
              <img src={instagram} alt="instagram" />
            </Link>
            <Link to="/" className="mx-2">
              <img src={youtube} alt="youtube" />
            </Link>
            <Link to="/" className="ms-2">
              <img src={alo} alt="alo" />
            </Link>
          </div>
        </div>
      </div> */}
      {/*Navbar*/}
      <div className="navigation-bar">
        <div className="container">
          <nav className="navbar">
            <Link to="/" className="logo">
              <img src={logo} alt="" className="logo-img" />
            </Link>
            <button className="navbar-btn" onClick={() => setNavbar(true)}>
              &#9776;
            </button>
            <div className={`navbar-content ${navbar ? 'show' : ''}`}>
              <button className="close-btn" onClick={() => setNavbar(false)}>
                &#9747;
              </button>
              <div className="menu fw-bold">
                <Link to="/" className="menu-item" onClick={() => setNavbar(false)}>
                  Anasayfa
                </Link>
                {/* <Link
                  to="/baskandan"
                  className="menu-item"
                  onClick={() => setNavbar(false)}
                >
                  Başkandan
                </Link>
                <Link
                  to="/projeler"
                  className="menu-item"
                  onClick={() => setNavbar(false)}
                >
                  Projeler
                </Link> */}
                {/*<Link
                  to="/oylamaya-katil"
                  className="menu-item"
                  onClick={() => setNavbar(false)}
                >
                  Oylamaya Katıl
              </Link> */}
                {/* {Object.entries(user).length > 0 && (
                  <>
                    <Link to="/proje-ekle" className="menu-item" onClick={() => setNavbar(false)}>
                      Proje Ekle
                    </Link>
                  </>
                )} */}
                <Link to="/secilen-projeler" className="menu-item" onClick={() => setNavbar(false)}>
                  Seçilen Projeler
                </Link>
                <Link to="/sorular" className="menu-item" onClick={() => setNavbar(false)}>
                  S.S.S
                </Link>
                <Link to="/" className="menu-item" onClick={() => downloadFile()}>
                  Bütçem İstanbul Kitabı
                </Link>
                <Link to="/iletisim" className="menu-item" onClick={() => setNavbar(false)}>
                  İletişim
                </Link>
              </div>
              <div className="btn-group">
                {Object.entries(user).length > 0 ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic" className="px-4">
                        Hoşgeldin,&nbsp;{user?.firstName}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/*<Dropdown.Item href="/hesabim?eventKey=vote">
                          Projelerim
                        </Dropdown.Item>  */}
                        <Dropdown.Item onClick={() => logOut()}>Çıkış Yap</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/*<a
                      variant="primary"
                      href="/hesabim?eventKey=selected"
                      style={{ marginLeft: "15px" }}
                      className="position-relative my-lg-0 my-4"
                    >
                      <img src={cartImg} alt="" />
                      <Badge
                        bg="danger"
                        className="text-white rounded-circle position-absolute top-0 start-100 translate-middle"
                      >
                        {selectedProjects?.items?.length > 0
                          ? selectedProjects?.items?.length
                          : "0"}
                      </Badge>
                    </a>
                    {selectedProjects && (
                      <div className="text-primary ms-3 text-nowrap">
                        {
                          <strong className="fs-5">
                            {selectedProjects?.citizenRemainderMoneyPoint}
                          </strong>
                        }
                        &nbsp;Puan
                      </div>
                      )}  */}
                  </>
                ) : (
                  <>
                    <button
                      className="login-btn fw-bold"
                      onClick={() => {
                        OAuth.login();
                        setNavbar(false);
                      }}>
                      {'GİRİŞ YAP'}
                    </button>
                    <button className="istanbul-btn" onClick={handleIstanbulLogin}>
                      <img src={girisIcon} alt="" />
                      &nbsp;&nbsp;İSTANBUL SENİNLE GİRİŞ
                    </button>
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
