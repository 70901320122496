import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getDataAPI, getDataAPI2, postDataAPI, postDataAPI2} from '../../utility/fetchData';

export const allProjects = createAsyncThunk('/allprojects', async (_, thunkAPI) => {
  console.log('allProject ru:');
  try {
    return await getDataAPI2('Project/allprojects');
  } catch (error) {
    console.error(error);
  }
});

//exporting all projects this url , it works with no token
export const getAllProjectsForSearching = createAsyncThunk('/getAllProjectsForSearching', async (searchData, thunkAPI) => {
  try {
    if (searchData) return await postDataAPI2('/WebProject/searchprojects', searchData);

    const response = await postDataAPI2('/WebProject/searchprojects', {});
    return response;
  } catch (error) {
    console.error(error);
  }
});

export const handleProjectsOnTheVote = createAsyncThunk('/projects-on-the-vote', async (_, thunkAPI) => {
  try {
    return await getDataAPI2('/WebProject/allprojects');
  } catch (error) {
    console.error(error);
  }
});

export const projectDetail = createAsyncThunk('/projectDetail', async (id, thunkAPI) => {
  try {
    if (id) return await getDataAPI2(`/WebProject/projectDetail?id=${id}`);
  } catch (error) {
    console.error(error);
  }
});

// miniapp te kullanılan searc servisi
export const searchProject = createAsyncThunk('/searchProject', async (searchData, thunkAPI) => {
  try {
    if (searchData) return await postDataAPI2('/Project/searchprojects', searchData);
  } catch (error) {
    console.error(error);
  }
});

export const searchProjectOnTheVote = createAsyncThunk('/searchProjectOnTheVote', async (searchData, thunkAPI) => {
  try {
    if (searchData)
      return await postDataAPI2('/WebProject/searchprojects', {
        projectName: searchData,
      });
  } catch (error) {
    console.error(error);
  }
});

// export const createProject = async (data) => {
//   const response =  await postDataAPI('/Project/create-project', data, {
//       headers: {
//           "Content-Type": "multipart/form-data"
//       }
//   })

//   return response;
// }

export const getProjectUnsaved = createAsyncThunk(
  'getProjectUnsaved',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Project/getunsavedproject');

      // console.log("3", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

// export const getProjectUnsaved = async () => {
//   return await getDataAPI2('/Project/getunsavedproject')
// }

const initialState = {
  isLoading: false,
  projects: [],
  project: {},
  projectsOnTheVote: [],
};

const ProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(allProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.projects = [];
      })

      //
      .addCase(getAllProjectsForSearching.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectsForSearching.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(getAllProjectsForSearching.rejected, (state, action) => {
        state.isLoading = false;
        state.projects = [];
      })
      //

      .addCase(handleProjectsOnTheVote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleProjectsOnTheVote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectsOnTheVote = action.payload;
      })
      .addCase(handleProjectsOnTheVote.rejected, (state, action) => {
        state.isLoading = false;
        state.projectsOnTheVote = [];
      })
      .addCase(projectDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(projectDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.project = action.payload;
      })
      .addCase(projectDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.project = {};
      })
      .addCase(searchProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(searchProject.rejected, (state, action) => {
        state.isLoading = false;
        state.projects = [];
      })
      .addCase(searchProjectOnTheVote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchProjectOnTheVote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectsOnTheVote = action.payload;
      })
      .addCase(searchProjectOnTheVote.rejected, (state, action) => {
        state.isLoading = false;
        state.projectsOnTheVote = [];
      });
  },
});

export const {} = ProjectSlice.actions;
export default ProjectSlice.reducer;
