import { api, apiNoToken } from "../services/api";

export const getDataAPI = async (url) => {
  const { data } = await api.get(`${url}`, {});
  // console.log("fetchData", data);
  return data.data;
};

export const postDataAPI = async (url, dataObject) => {
  const { data } = await api.post(`${url}`, dataObject, {});
  // if (!data.data) {
  //   console.log(data.message);
  //   return data.message;
  // }
  
  return data;
};

export const deleteDataAPI = async (url) => {
  const { data } = await api.delete(`${url}`, {});
  // console.log("fetchData", data);
  return data.data;
};

export const getDataAPI2 = async (url) => {
  const { data } = await apiNoToken.get(`${url}`, {});
  // console.log("fetchData", data);
  return data.data;
};

export const postDataAPI2 = async (url, dataObject) => {
  const { data } = await apiNoToken.post(`${url}`, dataObject, {});
  // if (!data.data) {
  //   console.log(data.message);
  //   return data.message;
  // }
  // console.log(data);
  return data.data;
};

export const deleteDataAPI2 = async (url) => {
  const { data } = await apiNoToken.delete(`${url}`, {});
  // console.log("fetchData", data);
  return data.data;
};