import {Col, Container, Row} from 'react-bootstrap';
import {useParams, Link} from 'react-router-dom';
//icons
import calendar from '../../assets/images/projects/takvim.svg';
import theme from '../../assets/images/projects/proje.svg';
import group from '../../assets/images/projects/hedef.svg';
import oylama from '../../assets/images/projects/oylama.svg';
import favorite from '../../assets/images/projects/favorite.svg';
import locationImg from '../../assets/images/standart/location.png';
//images
import card_img from '../../assets/images/news/card_img.png';
import thm1 from '../../assets/images/projects/tema1.svg';
import thm2 from '../../assets/images/projects/tema2.svg';
import thm3 from '../../assets/images/projects/tema3.svg';
import butceImg from '../../assets/images/projects/Group 14847.png';
import {useEffect, useState} from 'react';
import {projectDetail} from '../../redux/slices/ProjectSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedProjects, submittedProjects} from '../../redux/slices/BasketSlice';
import {apiNoToken} from '../../services/api';
import Swal from 'sweetalert2';
import {setFilter} from '../../redux/slices/filterSlice';

const ProjectsDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {isLoading, project} = useSelector((state) => state.projects);

  const {filter} = useSelector((state) => state.filter);

  const [projectSka, setProjectSka] = useState([]);
  const [projectResultSka, setProjectResultSka] = useState([]);

  const getSka = async () => {
    try {
      const {data} = await apiNoToken.get(`Lookups/ProjectSustainableFieldsOfActivity`);
      setProjectSka(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(projectDetail(id));
    getSka();
    dispatch(
      setFilter({yilFilter: filter['yilFilter'], targetFilter: filter['targetFilter'], themeFilter: filter['themeFilter'], isFilter: true}),
    );
  }, [id, dispatch]);

  useEffect(() => {
    if ((projectSka !== null || projectSka !== undefined) && projectSka?.length > 0) {
      projectSka.map((item) => {
        if (project?.projectSustainableFieldsOfActivities?.includes(item?.id)) {
          setProjectResultSka((prev) => [...prev, item?.imageUrl]);
        }
        return item;
      });
    }
  }, [project, projectSka]);

  const addCart = async (id) => {
    try {
      const {data} = await apiNoToken.post(`Basket/add-project-item-to-basket?toVoteProjectId=${id}`);
      if (data.message) {
        Swal.fire('Uyarı', data.message, 'warning');
      } else {
        dispatch(submittedProjects(data.data));
        Swal.fire('Başarılı', 'Sepete Eklendi', 'success');
        dispatch(getSelectedProjects());
      }
    } catch (err) {
      Swal.fire('Hata', err, 'error');
    }
  };

  return (
    <>
      {isLoading ? (
        'Yükleniyor...'
      ) : (
        <Container className="mt-5">
          <Link to="/secilen-projeler" className="back-btn fw-bold border rounded">
            <span className="me-3">&#60;</span>Projelere Geri Dön
          </Link>
          <hr className="text-primary" />

          <Row className="d-flex">
            {/*sidebar*/}
            <Col className="side-bar" md={3}>
              <Row className={`${project?.budget ? 'py-5' : ''}`}>
                {/* {project?.budget && (
                  <Col className="col-9 total text-center py-3 w-100">
                    <span className="total_score">
                      <span className="fs-2 fw-extra-bold text-primary ">
                        {project?.budget}
                      </span>
                      <b className="fw-bold text-primary">Puan</b>
                    </span>
                  </Col>
               )} */}
                <Col className="col-12 profile mt-5 py-4">
                  <h6 className="text-primary fw-bold">PROJE BİLGİLERİ</h6>
                  {/* <span className="fw-regular">
                    <span>
                      <img src={vote} alt="oylama_icon" />
                    </span>
                    &nbsp; Oy Oranı:&nbsp;
                    <span className="text-primary fw-bold">
                      {project?.voteRate ? project?.voteRate : "%0"}
                    </span>
                  </span>
                  <br /> */}
                  {project?.duration !== null && (
                    <span className="fw-regular">
                      <span>
                        <img src={calendar} alt="takvim_icon" />
                      </span>
                      &nbsp; Proje Süresi:&nbsp;
                      <span className="text-primary fw-bold">{project?.duration}</span>
                    </span>
                  )}
                  {/* <span className="fw-regular">
                    <span>
                      <img src={money} alt="para_icon" />
                    </span>
                    &nbsp; Proje Maliyeti:&nbsp;
                    <span className="text-primary fw-bold">
                      120000&#8378;
                    </span>{" "}
                  </span> */}
                  <br />
                  <span className="fw-regular">
                    <span>
                      <img src={theme} alt="proje_icon" />
                    </span>
                    &nbsp; Proje Teması:&nbsp;
                    <span className="text-primary fw-bold">{project?.projectTheme?.themeName}</span>
                  </span>
                  <br />
                  <span className="fw-regular">
                    <span>
                      <img src={group} alt="grup_icon" />
                    </span>
                    &nbsp; Hedef Grup:&nbsp;
                    <span className="text-primary fw-bold">{project?.projectTargetGroup?.targetName}</span>
                  </span>
                  <span className="fw-regular d-block">
                    <span>
                      <img src={calendar} alt="takvim_icon" />
                    </span>
                    &nbsp; Proje Yılı:&nbsp;
                    <span className="text-primary fw-bold">{project?.projectYear}</span>
                  </span>
                  <span className="fw-regular d-block">
                    <span>
                      <img src={locationImg} alt="location" style={{transform: 'translateX(-5px)'}} />
                    </span>
                    İlçe:&nbsp;
                    <span className="text-primary fw-bold">{project?.district?.name}</span>
                  </span>
                  {project?.voteRate !== null ? (
                    <span className="fw-regular d-block">
                      <span>
                        <img src={oylama} alt="votecount" style={{transform: 'translateX(-2px)'}} />
                      </span>
                      {/* &nbsp;Oy Sayısı:&nbsp;
                    <span className="text-primary fw-bold">{Math.ceil(project?.calculatedTotalPoints)}</span> */}
                      &nbsp;Oy Oranı:&nbsp;
                      <span className="text-primary fw-bold">{'%' + project?.voteRate?.toFixed(2)}</span>
                    </span>
                  ) : null}
                </Col>
                {/* <Col className="col-9 calendar mt-3">
                  <h6 className="text-primary fw-bold">PROJE TAKVİMİ</h6>
                  <div className="adim1">
                    <span className="fw-regular">1.Adım</span>
                    <br />
                    <span className="text-primary fw-regular">
                      Başlangıç Tarihi:
                      <span className="text-dark">11.05.2022</span>
                    </span>
                    <br />
                    <span className="text-primary fw-regular">
                      Bitiş Tarihi:
                      <span className="text-dark">11.05.2022</span>
                    </span>
                  </div>
                  <div className="adim2 mt-4">
                    <span>2.Adım</span>
                    <br />
                    <span className="text-primary fw-regular">
                      Başlangıç Tarihi:
                      <span className="text-dark">11.05.2022</span>
                    </span>
                    <br />
                    <span className="text-prmary fw-regular">
                      Bitiş Tarihi:
                      <span className="text-dark">11.05.2022</span>
                    </span>
                  </div>
                </Col> */}
                {/* <Col className="col-9 icons mt-5">
                  <div>
                    <div className="fav-btn" onClick={() => addCart(id)}>
                      Sepete Ekle
                      <span className="ms-3">
                        <img src={favorite} alt="favori_icon" />
                      </span>
                    </div>
                  </div>
                  {/* <div>
                    <p className="text-primary mt-5">Projeyi Paylaş</p>
                    <span className="">
                      <img src={twitter} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={whatsapp} alt="" />
                    </span>
                    <span>
                      <img src={facebook} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={instagram} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={linkedin} alt="" />
                    </span>
                    <span>
                      <img src={google} alt="" />
                    </span>
                  </div> 
                </Col> */}
              </Row>
            </Col>
            <Col md={9} className="mt-md-0 mt-4">
              <Row>
                <Col md={8} className="px-3 left_card">
                  <h2 className="text-primary fw-bold">{project?.title}</h2>
                  <div className="">
                    <h5 className="text-primary fw-bold mb-4 mt-4">Projenin Amacı</h5>
                    <p className="fw-regular fs-6" dangerouslySetInnerHTML={{__html: project?.purpose}} />
                    <h5 className="text-primary fw-bold mb-4 mt-4">Projenin Özeti</h5>
                    {/* <div dangerouslySetInnerHTML={{ __html: project?.summary }} />  */}
                    <p className="fw-regular fs-6" dangerouslySetInnerHTML={{__html: project?.summary}} />
                    {project?.activities && (
                      <div>
                        <h5 className="text-primary fw-bold mb-4 mt-4">Proje Kapsamında Ana Adımlar/Faaliyetler</h5>
                        <p className="fw-regular fs-6" dangerouslySetInnerHTML={{__html: project?.summary}} />
                        {/* <div dangerouslySetInnerHTML={{ __html: project?.activities }} />  */}
                      </div>
                    )}
                    {project?.expectedResults && (
                      <div>
                        <h5 className="text-primary fw-bold mb-4 mt-4">Projeden Beklenen Sonuçlar</h5>
                        <p className="fw-regular fs-6" dangerouslySetInnerHTML={{__html: project?.expectedResults}} />
                        {/* <p className="fw-regular fs-6">{project?.expectedResults}</p> */}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={4} className=" right_card p-0">
                  <div className="w-100 h-100 position-relative text-center right_img_card">
                    <span className="shaping bg-primary fs-sm ">
                      <span className="py-1 text-center mx-auto">Proje Kodu: {project?.projectCode}</span>
                    </span>
                    <img className="card_img " src={project?.projectImageUrl ? project?.projectImageUrl : butceImg} alt={project?.title} />
                    {project?.isMerged && (
                      <span className="shaping2 bg-secondary fs-sm ">
                        <span className="py-1 text-center mx-auto">Birleştirilmiş Projedir.</span>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="d-flex mt-5">
                {projectResultSka?.length > 0 && (
                  <Col className="col-9">
                    <h6 className="fw-bold text-primary">SKA</h6>
                    <img src={projectResultSka[0]} alt="Proje Resmi" className="tema_img mt-2 me-2" />
                  </Col>
                )}
                {/* <Col className="col-9 mt-5">
                  <Tabs
                    defaultActiveKey="time"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                  >
                    <Tab eventKey="time" title="Projenin Süresi">
                      {project?.duration}
                    </Tab>
                    <Tab eventKey="document" title="Proje Dökümanları"></Tab>
                    <Tab eventKey="status" title="Proje Durumu">
                      {project?.projectStatus}
                    </Tab>
                  </Tabs>
                </Col> */}
                {/* <Col className="col-9 mt-5">
                  <div>
                    <div className="fav-btn" onClick={() => addCart(id)}>
                      Sepete Ekle
                      <span className="ms-2">
                        <img src={favorite} alt="favori_icon" />
                      </span>
                    </div>
                  </div>
                  {/* <div>
                    <p className="text-primary mt-5">Projeyi Paylaş</p>
                    <span className="">
                      <img src={twitter} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={whatsapp} alt="" />
                    </span>
                    <span>
                      <img src={facebook} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={instagram} alt="" />
                    </span>
                    <span className="px-1">
                      <img src={linkedin} alt="" />
                    </span>
                    <span>
                      <img src={google} alt="" />
                    </span>
                  </div> 
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProjectsDetails;
