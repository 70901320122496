const TCKN_REGEX = /([0-9]{11})/;

function isTckn(value) {
  return TCKN_REGEX.test(value);
}

function compose(...funcs) {
  return funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg,
  );
}

function toDisplayDate(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return day + '.' + month + '.' + year;
  } else {
    return '';
  }
}

// ayın gününü veren fonk
const getDate = () => {
  const fullDate = new Date();
  const day = fullDate?.getDate();
  return day;
};

// function toDislayDateTime(timestamp) {
//   if (timestamp) {
//     const date = new Date(timestamp);
//     const year = date.getFullYear();
//     const month = ('0' + (date.getMonth() + 1)).slice(-2);
//     const day = ('0' + date.getDate()).slice(-2);

//     const hours = ('0' + date.getHours()).slice(-2);
//     const seconds = ('0' + date.getSeconds()).slice(-2);

//     return day + '/' + month + '/' + year
//   } else {
//     return '';
//   }
// }

function makeInitValues(fields, obj = {}) {
  Object.keys(fields).forEach((el) => {
    obj[el] = '';
  });
  return obj;
}

function parseSearch(search) {
  const params = {};
  if (typeof search === 'string' && search.length > 1) {
    search
      .substring(1)
      .split('&')
      .forEach((str) => {
        const [key, value] = str.split('=');
        params[key] = decodeURIComponent(value);
      });
  }
  return params;
}

export {
  compose,
  toDisplayDate,
  // toDislayDateTime,
  parseSearch,
  makeInitValues,
  isTckn,
  getDate,
};
