import React from "react";
import { Link } from "react-router-dom";
import cardImg from "../../assets/images/projects/Group 14847.png";
import favorite from "../../assets/images/projects/favorite.svg";
import { useDispatch } from "react-redux";
import {
  getSelectedProjects,
  allVotedProjectByCitizen,
  submittedProjects,
} from "../../redux/slices/BasketSlice";
import Swal from "sweetalert2";
import { api } from "../../services/api";

const ProjectsCard = ({ item, index }) => {
  const dispatch = useDispatch();

  {/*const addCart = async (id) => {
    try {
      const { data } = await api.post(
        `Basket/add-project-item-to-basket?toVoteProjectId=${id}`
      );
      if (data.message) {
        Swal.fire("Uyarı", data.message, "warning");
      } else {
        dispatch(submittedProjects(data.data));
        Swal.fire("Başarılı", "Sepete Eklendi", "success");
        dispatch(getSelectedProjects());
      }
    } catch (err) {
      Swal.fire("Hata", err, "error");
    }
  };  */}

  return (
    <>
      <div className="card-container mb-3">
        <div className="card project-card border-0 mx-2">
          <Link
            to={`/projeler/${item?.id && item?.id}`}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              className="card-img-top"
              src={item?.projectImageUrl ? item?.projectImageUrl : cardImg}
              alt=""
              style={{ maxWidth: "85%", maxHeight: "85%" }}
            />
          </Link>

          <span className="number-shape bg-warning fw-bold">{index}</span>
          {/* <span className="number-shape bg-warning fw-bold fs-6">{item?.projectYear}</span> */}


        {/*  {item?.budget && (
            <span className="shape bg-primary fw-bold">
              {item?.budget} Puan
            </span>
        )} */}
          <div className="card-body card-content">
            <Link
              to={`/projeler/${item?.id && item.id}`}
              className="card-title fw-bold text-primary"
              style={{fontSize: 20}}
            >
              {item?.title && item?.title}
            </Link>
            {/* <p className="card-text fw-regular">
              {item?.summary && item?.summary?.length > 80
                ? item?.summary?.substr(0, 80) + " ..."
                : item?.summary}
            </p> */}
          {/*  {basket && (
              <button
                className="btn btn-light border-primary fw-semibold text-primary w-100 d-flex align-items-center justify-content-between"
                onClick={() => addCart(item?.id)}
              >
                <span className="txt">Sepete Ekle</span>
                <span>
                  <img className="favorite_img" src={favorite} alt="" />
                </span>
              </button>
          )} */}
            {/* <Link
              to={`/projeler/${item.id}`}
              className="btn btn-primary fw-semibold w-100 mt-3"
            >
              <span className="txt">Projeyi incele</span>
              <span>
                <img className="search_img " src={search} alt="" />
              </span>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsCard;
