import React from "react";
import bannerImage from "../../assets/images/standart/MaskGroup93.png";
import butceBannerImage from "../../assets/images/standart/oylamaya_katil.jpg";

export default function Banner({ text, butceBanner }) {
  const bannerStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "220px",
    backgroundRepeat: "no-repeat",
    marginTop: "30px",
  };

  if (butceBanner) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <img src={`${butceBannerImage}`} alt="" className="container" />
      </div>
    );
  }

  return (
    <div className="Banner fw-extra-bold ">
      <div
        className="d-flex align-items-center justify-content-center text-white fs-4 mt-20"
        style={bannerStyle}
      >
        <h1 className="Title">{text}</h1>
      </div>
    </div>
  );
}
