import React from "react";
import footerLogo from "../../assets/images/home/footer.png";
import footerTopLogo from "../../assets/images/standart/butce-senin-footer-logo.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="d-flex align-items-center justify-content-center">
        {/* <img
          src={footerTopLogo}
          alt=""
          className="mt-5"
          style={{ maxWidth: "80%" }}
        /> */}
      </div>
      <img style={{ width: "100%" }} src={footerLogo} alt="" />
    </div>
  );
}
