import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
// import { AppStore } from "./store";
import ProtectedRoute from "./pages/ProtectedRoute";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import store from "./redux/store";
import {
  allVotedProjectByCitizen,
  getSelectedProjects,
  getSubmittedProjects,
} from "./redux/slices/BasketSlice";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (Object.entries(user)?.length > 0) {
      dispatch(getSelectedProjects());
      dispatch(getSubmittedProjects());
      //dispatch(allVotedProjectByCitizen());
    }
  }, [dispatch, user]);

  if (window.location.pathname === "/giris") {
    window.location.href = "/";
  }

  return (
    // <BrowserRouter>
    //   <Provider store={AppStore}>
    //     <div>
    //       <Header />
    //       <Switch>
    //         <Route exact path="/" component={require("./pages/Home").default} />
    //         <Route
    //           exact
    //           path="/baskandan"
    //           component={require("./pages/President").default}
    //         ></Route>
    //         <Route
    //           exact
    //           path="/sorular"
    //           component={require("./pages/Questions").default}
    //         ></Route>

    //         <Route
    //           exact
    //           path="/iletisim"
    //           component={require("./pages/Communication").default}
    //         ></Route>

    //         <Route path="/giris">
    //           <ProtectedRoute />
    //         </Route>
    //         <Route
    //           exact
    //           path="/haberler"
    //           component={require("./pages/News").default}
    //         />
    //           <Route
    //             exact
    //             path="/projeler"
    //             component={require("./pages/Projects").default} />
    //           <Route
    //           path="/projeler/:id"
    //           component={require("./pages/ProjectsDetail").default}
    //         />

    //         <Route
    //           path="/haberler/:id"
    //           component={require("./pages/NewsDetail").default}
    //         />
    //         <Route
    //           path="/hesabim"
    //           component={require("./pages/MyAccount").default}
    //         />

    //         <Route component={require("./pages/NotFound").default} />
    //       </Switch>
    //       <Footer />
    //     </div>
    //   </Provider>
    // </BrowserRouter>
    <BrowserRouter>
      {/* <Provider store={store}> */}
        <div>
          <Header />
          <Switch>
            
            <Route exact path="/" component={require("./pages/Home").default} />
            {/* <Route
            exact
            path="/baskandan"
            component={require("./pages/President").default}
          ></Route> */}
            <Route
              exact
              path="/sorular"
              s
              component={require("./pages/Questions").default}
            ></Route>

            <Route
              exact
              path="/iletisim"
              component={require("./pages/Communication").default}
            ></Route>

            
            {/* <Route
              exact
              path="/proje-ekle"
              component={require("./pages/AddProject").default}
            ></Route> */}

            <Route path="/giris">
              <ProtectedRoute />
            </Route>

            {/* <Route
            exact
            path="/projeler"
            component={require("./pages/Projects").default}
          /> */}
           <Route
              exact
              path="/oylamaya-katil"
              component={require("./pages/joinVote").default}
        /> 
           <Route
              exact
              path="/secilen-projeler"
              component={require("./pages/SelectedProjects").default}
        /> 
            <Route
              path="/projeler/:id"
              component={require("./pages/ProjectsDetail").default}
            />

            <Route
              path="/haberler/:id"
              component={require("./pages/NewsDetail").default}
            />
            <Route
              path="/hesabim"
              component={require("./pages/MyAccount").default}
            />

            <Route component={require("./pages/NotFound").default} />
          </Switch>
          <Footer />
        </div>
      {/* </Provider> */}
    </BrowserRouter>
  );
}

export default App;
