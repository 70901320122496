import config from "../services/config";

const STORE_NAME = config.OAUTH_APP_NAME;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORE_NAME);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORE_NAME, serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
