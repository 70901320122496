const config = {
  API_URL: process.env.REACT_APP_API_URL,
  PROFILE_URL: process.env.REACT_APP_PROFILE_URL,
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  OAUTH_APP_NAME: process.env.REACT_APP_OAUTH_APP_NAME,
  OAUTH_TOKEN_URL: process.env.REACT_APP_OAUTH_TOKEN_URL,
  OAUTH_REVOKE_TOKEN_URL: process.env.REACT_APP_OAUTH_REVOKE_TOKEN_URL,
  OAUTH_AUTHORIZE_URL: process.env.REACT_APP_OAUTH_AUTHORIZE_URL,
  OAUTH_USERINFO_URL: process.env.REACT_APP_OAUTH_USERINFO_URL,
  OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
  OAUTH_SCOPE: process.env.REACT_APP_OAUTH_SCOPE,
  OAUTH_REDIRECT_URI: process.env.REACT_APP_OAUTH_REDIRECT_URI
}

export default config