import myAccountCardImg from "../../assets/images/projects/Group 14847.png";
import deleteIcon from "../../assets/images/my_account/delete.png";
import { useDispatch } from "react-redux";
import { deleteItemToBasket } from "../../redux/slices/BasketSlice";
import Swal from "sweetalert2";

const MyAccountCard = ({
  selected,
  item,
  checkedProjects,
  setCheckedProjects,
}) => {
  const handleCheckedProject = (data) => {
    if (checkedProjects.includes(data)) {
      setCheckedProjects(checkedProjects.filter((el) => el !== data));
    } else {
      setCheckedProjects((prev) => [...prev, data]);
    }
  };

  const dispatch = useDispatch();

  const handleDelete = (id) => {
    Swal.fire({
      title: "Projeyi silmek istediğinize emin misiniz?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteItemToBasket(id));
        Swal.fire("Proje Silindi", "", "success");
      }
    });
  };

  return (
    <div className="my-account-card py-4">
      <div className="row">
        <div className="col-md-2 mb-lg-0 mb-2">
          <div className="my-account-card-photo">
            <img
              src={
                item?.projectImageUrl ? item?.projectImageUrl : myAccountCardImg
              }
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="my-account-card-content">
            <div>
              <h3 className="my-account-card-content-title fs-3">
                {item?.toVoteProjectTitle
                  ? item?.toVoteProjectTitle
                  : item?.title}
              </h3>
              {item?.toVoteProjectCategory && (
                <h6 className="my-account-card-content-subtitle">
                  Kategori:&nbsp;{item?.toVoteProjectCategory}
                </h6>
              )}
            </div>
            <div className="my-account-card-content-point">
              <span className="big-point fs-4">
                {item?.toVoteProjectMoneyPoint &&
                  item?.toVoteProjectMoneyPoint + " Puan"}
                {item?.budget && item?.budget + " Puan"}
              </span>
            </div>
          </div>
        </div>
        {selected && (
          <div className="col-md-2">
            <div className="my-account-card-action">
              <button
                className="delete-btn"
                onClick={() => handleDelete(item?.toVoteProjectId)}
              >
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccountCard;
