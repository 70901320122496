import React, { useEffect, useState } from "react";
//components
import Banner from "../../components/Banner";
import Filter from "../../components/Filter";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination";
import { Alert, Col, Container, Row } from "react-bootstrap";
import ProjectsCard from "../../components/ProjectsCard";
import AlertMessage from "../../components/AlertMessage";
import PageLoading from "../../components/PageLoading";

import { useDispatch, useSelector } from "react-redux";
import {
  handleProjectsOnTheVote,
  searchProjectOnTheVote,
} from "../../redux/slices/ProjectSlice";

import { useLocation } from "react-router-dom";
import { Message } from "../../utility/message";
import { OAuth } from "../../services/oauth";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const JoinVote = () => {
  const dispatch = useDispatch();
  const { isLoading, projectsOnTheVote } = useSelector(
    (state) => state.projects
  );
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const { msg } = useSelector((state) => state.basket);

  useEffect(() => {
    if (msg) {
      return <Message title="Uyarı" description={msg} />;
    }
  }, [msg]);

  const [search, setSearch] = useState("");

  const [joinProjects, setJoinProjects] = useState([]);

  const query = useQuery();

  const yearQuery = query?.get("year")?.split(",");

  useEffect(() => {
    if (!search) dispatch(handleProjectsOnTheVote());
  }, [dispatch, search]);

  useEffect(() => {
    if (search) dispatch(searchProjectOnTheVote(search));
  }, [search, dispatch]);

  useEffect(() => {
    if (projectsOnTheVote?.length > 0) {
      const mergedProjects = projectsOnTheVote.reduce((acc, curr) => {
        acc.push(...curr.projects);
        return acc;
      }, []);
      setJoinProjects(mergedProjects);
    } else if (yearQuery?.length > 0) {
      const filteredProject = yearQuery
        ?.map((el) => projectsOnTheVote.filter((item) => item.year === el))
        .flat()
        .flatMap((element) => element);
      if (filteredProject?.length > 0) {
        const mergedProjects = filteredProject.reduce((acc, curr) => {
          acc.push(...curr.projects);
          return acc;
        }, []);
        setJoinProjects(mergedProjects);
      }
    } else {
      setJoinProjects(projectsOnTheVote);
    }
  }, [projectsOnTheVote, search, query]);

  console.log(joinProjects);

  return (
    <>
      <Banner butceBanner />
      <Container className="mt-5">
        <SearchBar search={search} setSearch={setSearch} /> 
        <hr className="mb-4 page-hr" />
         {/*  <Row className="mb-4 mt-2">
            {/* <Col md={12}>
              {/* <h3 className="fs-md-4  fs-5 text-md-start text-center mb-4">
                “2024 Yılı Katılımcı Bütçe Uygulaması, Proje Oylamaları Başladı!”
              </h3> 

              <p className="fw-open-medium fs-6 text-justify text-dark-silver lh-lg">
                İBB’nin 2024 yılı bütçesi, 2022 ve 2023 yıllarında olduğu gibi bu
                yılda vatandaşlarımızla birlikte, katılımcılıkla hazırlanacak
                olup, üçüncüsü düzenlenen Katılımcı Bütçe uygulaması bu yıl Afet
                ve Risk Yönetimi temasında gerçekleştirilmektedir. İstanbulumuzu
                afetlere hazırlamak için, Türkiye’nin her yerinden
                vatandaşlarımızın İstanbul Senin/Bütçe Senin uygulaması ve
                butcesenin.ibb.istanbul web sitesi üzerinden
                <strong>&nbsp;07 - 13 Ağustos 2023&nbsp;</strong>
                tarihleri arasında değerli katkı ve oylarını bekliyoruz.
              </p>
              {/* <p className="fw-open-medium fs-6 text-justify text-dark-silver lh-lg">
                İstanbulumuzu afetlere hazırlamak için, Türkiye’nin her yerinden
                vatandaşlarımızın İstanbul Senin/Bütçe Senin uygulaması ve
                butcesenin.ibb.istanbul web sitesi üzerinden değerli katkı ve
                oylarını bekliyoruz.
              </p> */}
              {/*<p className="fw-open-medium fs-6 text-justify text-dark-silver lh-lg">
                Haydi! Bütçe Senin, Karar Senin, İstanbul’da hayat senin projenle,
                senin kararınla güzelleşsin.
              </p>
            </Col> */}

            
         {/*  <Col md={12}>
              {/* <h3 className="fs-md-4  fs-5 text-md-start text-center mb-4">
                “2024 Yılı Katılımcı Bütçe Uygulaması, Proje Oylamaları Başladı!”
              </h3> 

              <p className="fw-open-medium fs-6 text-justify text-dark-silver lh-lg">
                Oylama süreci tamamlanmış olup oylama sonuçları
                <strong>&nbsp;İstanbul Senin / Bütçe Senin Uygulaması </strong> ve  <strong> butcesenin.istanbul &nbsp;</strong>
                web sitesi üzerinden paylaşılacaktır.
              </p>
              
              <p className="fw-open-medium fs-6 text-justify text-dark-silver lh-lg">
              Göstermiş olduğunuz ilgi için teşekkür ederiz.  
              </p>
            </Col>  
          </Row>   */}
        <hr className="mb-4 page-hr" />
       {/*  <Row>
          <Col className="col-md-3  mb-lg-0 ">
            <Filter />
          </Col>
          {isLoading ? (
            "Yükleniyor..."
          ) : (
            <Col className="col-md-9">
              <Row className="g-0">
                {search && projectsOnTheVote.length === 0 && (
                  <AlertMessage variant="warning" message="Sonuç Bulunamadı." />
                )}
                {!isLoading &&
                  joinProjects?.length > 0 &&
                  joinProjects?.map((item, key) => (
                    <Col lg={4} md={6} className="mb-lg-0" key={key}>
                      <ProjectsCard item={item} basket />
                    </Col>
                  ))}
              </Row>
              <Row className="mt-5">
                <Col className="d-flex justify-content-center">
                  <Pagination />
                </Col>
              </Row>
            </Col>
          )}
        </Row>  */}
        
        <Row>
            {
              <Col>
                <Row className="g-0">
                  {search &&
                    (projectsOnTheVote === null ||
                      projectsOnTheVote?.length === 0) && (
                      <AlertMessage
                        variant="warning"
                        message="Sonuç Bulunamadı."
                      />
                  )}
                  {/* {Object.entries(user)?.length === 0 && (
                    <Alert variant="warning">
                      Oylamaya katılabilmek için{" "}
                      <span
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => OAuth.login()}
                      >
                        giriş
                      </span>{" "}
                      yapın.
                    </Alert>
                  )}
                  {Object.entries(user)?.length > 0 && isLoading && (
                    <PageLoading />
                  )} */}
                  {Object.entries(user)?.length > 0 &&
                    !isLoading &&
                    joinProjects?.length > 0 &&
                    joinProjects?.map((item, key) => (
                      <Col lg={4} md={6} className="mb-lg-0" key={key}>
                        <ProjectsCard item={item} basket index={key + 1} />
                      </Col>
                    ))}
                    {!search &&
                      Object.entries(user)?.length > 0 &&
                      !isLoading &&
                      (joinProjects?.length === 0 || joinProjects === null) && (
                        <Alert variant="warning">Proje Bulunmamaktadır.</Alert>
                      )}
                </Row>
              </Col>
            }
          </Row> 
        
        {/* <Row>
          <Col md={3} className="mb-md-0 mb-3">
            <Filter />
          </Col>
          <Col md={9}>
            <Row className="g-0">
              {search &&
                (projectsOnTheVote === null ||
                  projectsOnTheVote?.length === 0) && (
                  <AlertMessage variant="warning" message="Sonuç Bulunamadı." />
                )}
              {Object.entries(user)?.length === 0 && (
                <Alert variant="warning">
                  Oylamaya katılabilmek için{" "}
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => OAuth.login()}
                  >
                    giriş
                  </span>{" "}
                  yapın.
                </Alert>
              )}
              {Object.entries(user)?.length > 0 && isLoading && <PageLoading />}
              {Object.entries(user)?.length > 0 &&
                !isLoading &&
                joinProjects?.length > 0 &&
                joinProjects?.map((item, key) => (
                  <Col lg={4} md={6} className="mb-lg-0" key={key}>
                    <ProjectsCard item={item} basket index={key + 1} />
                  </Col>
                ))}
              {!search &&
                Object.entries(user)?.length > 0 &&
                !isLoading &&
                (joinProjects?.length === 0 || joinProjects === null) && (
                  <Alert variant="warning">Proje Bulunmamaktadır.</Alert>
                )}
            </Row>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default JoinVote;
