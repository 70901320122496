import React, {useState, useEffect} from 'react';
import {Accordion, Form, FormControl} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
//components
import '../../styles/components/_filter_accordion.scss';
import '../../styles/components/_filter.scss';
//

import {setFilter} from '../../redux/slices/filterSlice';

import {useHistory, useLocation} from 'react-router-dom';

import {selectDistricts, selectThemes, selectTarget, fetchDistricts, fetchThemes, fetchTarget} from '../../redux/slices/LooksupSlice';

import ProjectSlice, {getAllProjectsForSearching} from '../../redux/slices/ProjectSlice';

const FilterAccordion = ({setSearchedProjects, searchedProjects}) => {
  const dispatch = useDispatch();

  const {projects} = useSelector((state) => state.projects);
  const {targets, themes, districts} = useSelector((state) => state);
  const {filter} = useSelector((state) => state.filter);

  const date = new Date();

  const [item] = useState([+date.getFullYear() + 1, +date.getFullYear(), date.getFullYear() - 1, date.getFullYear() - 2]);

  // ilçe filtreleme yok
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const [selectedYear, setSelectedYear] = useState(filter?.yilFilter);

  const [selectedThemes, setSelectedThemes] = useState(filter?.themeFilter);

  const [selectedTarget, setSelectedTarget] = useState(filter?.targetFilter);

  const [selectedProjectName, setSelectedProjectName] = useState('');

  const [districtsData, setDistrictsData] = useState(districts?.districts);

  const [themesData, setThemesData] = useState(themes?.themes);

  const [targetsData, setTargetsData] = useState(targets?.targets);

  const [allProjects, setAllProjects] = useState([projects]);

  const [dataTEst, setdataTEst] = useState([]);

  const [searchedByYear, setSearchedByYear] = useState([]);

  //sayfa yüklendiğinde tüm projeler bir liste içinde toplanıyor
  const gettingAllProjects = (projectsArr) => {
    const allProjects = projectsArr?.reduce((acc, curr) => {
      acc.push(...curr.projects);
      return acc;
    }, []);
    return allProjects;
  };

  useEffect(() => {
    // tüm projeleri tek arr yap #1
    const allProjects = gettingAllProjects(projects);
    // allProjects && setAllProjects(allProjects); ---> dinamik değişken aramada kullanılmıştı (iptal)

    // tüm projeler tek arr haline dönmüşse listeleme için state e aktar #2
    //bu state pagination için kullanılıyor listeleme ile ilgili yok
    allProjects && setSearchedProjects(allProjects);
  }, [projects]);

  const handleYearCheckboxChange = (year) => {
    if (selectedYear.includes(year)) {
      setSelectedYear(selectedYear.filter((item) => item !== year));
    } else {
      setSelectedYear([...selectedYear, year]);
    }

    // handleFilterObject(selectedYear, selectedTarget, selectedThemes, false);
    // dispatch(
    //   setFilter({
    //     yilFilter: selectedYear || filter?.yilFilter,
    //     targetFilter: selectedTarget || filter?.targetFilter,
    //     themeFilter: selectedThemes || filter?.themeFilter,
    //   }),
    // );
    dispatch(setFilter({...filter, yilFilter: selectedYear}));
  };

  const handleThemesCheckboxChange = (id) => {
    if (selectedThemes.includes(id)) {
      setSelectedThemes(selectedThemes.filter((themeId) => themeId !== id));
    } else {
      // Eğer seçili değilse, listeye ekle
      setSelectedThemes([...selectedThemes, id.toString()]);
    }
    // handleFilterObject(selectedYear, selectedTarget, selectedThemes, false);
    // dispatch(
    //   setFilter({
    //     yilFilter: selectedYear || filter?.yilFilter,
    //     targetFilter: selectedTarget || filter?.targetFilter,
    //     themeFilter: selectedThemes || filter?.themeFilter,
    //   }),
    // );

    dispatch(setFilter({...filter, themeFilter: selectedThemes}));
  };

  //ilçeler servise gönderilelemediği için kullanılamayan bir func.
  const handleDistrictCheckboxChange = (id) => {
    if (selectedDistricts.includes(id)) {
      setSelectedDistricts(selectedDistricts.filter((districtId) => districtId !== id));
    } else {
      setSelectedDistricts([...selectedDistricts, id]);
    }
  };

  const handleTargetCheckboxChange = (id) => {
    if (selectedTarget.includes(id)) {
      setSelectedTarget(selectedTarget.filter((targetId) => targetId !== id));
    } else {
      setSelectedTarget([...selectedTarget, id]);
    }
    // handleFilterObject(selectedYear, selectedTarget, selectedThemes, false);
    // dispatch(
    //   setFilter({
    //     yilFilter: selectedYear || filter?.yilFilter,
    //     targetFilter: selectedTarget || filter?.targetFilter,
    //     themeFilter: selectedThemes || filter?.themeFilter,
    //   }),
    // );
    dispatch(
      setFilter({
        ...filter,
        targetFilter: selectedTarget,
      }),
    );
  };

  //get all districts
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchDistricts());
      setDistrictsData(response.payload);
    };

    districtsData?.length === 0 && fetchData();
  }, [dispatch]);

  //get all themes
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchThemes());
      setThemesData(response.payload);
    };

    themesData?.length === 0 && fetchData();
  }, [dispatch]);

  //get all targets
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchTarget());
      setTargetsData(response.payload);
    };

    targetsData?.length === 0 && fetchData();
  }, [dispatch]);

  // const handleFilterObject = (yearArr, targetArr, themeArr) => {
  //   dispatch(setFilter({yilFilter: yearArr, targetFilter: targetArr, themeFilter: themeArr}));
  // };

  // Searching..
  // useEffect(() => {
  //   handleSearchByYear();
  // }, [selectedYear]);

  // useEffect(() => {
  //   handleSearchByTheme();
  // }, [selectedThemes]);

  // useEffect(() => {
  //   handleSearchByDistricts();
  // }, [selectedDistricts]);

  // useEffect(() => {
  //   handleSearchByTarget();
  // }, [selectedTarget]);

  // const handleSearchByYear = () => {
  //   //years ['2022', '2023', '2024']
  //   if (selectedYear.length === 0) {
  //     setSearchedProjects(allProjects);
  //     return;
  //   }

  //   const srcByYear = selectedYear?.map((year) => allProjects?.filter((project) => project?.projectYear === Number(year)))?.flat();
  //   // console.log('srcByyear:', srcByYear);
  //   setSearchedProjects(srcByYear);
  //   setSearchedByYear(srcByYear);
  // };

  // const handleSearchByName = () => {
  //   if (selectedYear.length === 0) {
  //     setSearchedProjects(allProjects);
  //     return;
  //   }

  //   const srcByYear = selectedYear?.map((year) => allProjects?.filter((project) => project?.projectYear === Number(year)))?.flat();
  //   // console.log('srcByyear:', srcByYear);
  //   setSearchedProjects(srcByYear);
  //   setSearchedByYear(srcByYear);
  // };

  // const handleSearchByTheme = () => {
  //   let srchedThemes = [];
  //   if (selectedYear.length > 0) {
  //     handleSearchByYear();
  //     srchedThemes = searchedByYear?.filter((project) => selectedThemes?.includes(project?.projectTheme?.id));
  //   } else {
  //     handleSearchByYear();
  //     srchedThemes = allProjects?.filter((project) => selectedThemes?.includes(project?.projectTheme?.id));
  //   }
  //   // setSearchedByThemes(searchedProjects);
  //   // console.log('themesproject', srchedThemes);
  //   srchedThemes.length > 0 && setSearchedProjects(srchedThemes);
  // };

  // const handleSearchByDistricts = () => {
  //   let srchedDistricts = [];
  //   if (selectedYear.length > 0 && selectedThemes.length > 0) {
  //     handleSearchByTheme();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else if (selectedYear.length > 0) {
  //     handleSearchByYear();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else if (selectedThemes.length > 0) {
  //     handleSearchByTheme();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else {
  //     handleSearchByYear();
  //     srchedDistricts = allProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   }

  //   srchedDistricts.length > 0 && setSearchedProjects(srchedDistricts);
  // };

  // const handleSearchByTarget = () => {
  //   let srchedTargets = [];
  //   if (selectedYear.length > 0 && selectedThemes.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0 && selectedThemes.length > 0) {
  //     // handleSearchByTheme();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedThemes.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0) {
  //     // handleSearchByYear();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedThemes.length > 0) {
  //     // handleSearchByTheme();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedDistricts.length > 0) {
  //     handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else {
  //     // handleSearchByYear();
  //     srchedTargets = allProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   }

  //   // const srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   // console.log('srchedTargets:', srchedTargets);
  //   srchedTargets.length > 0 && setSearchedProjects(srchedTargets);
  // };

  //temizle
  const handleAllClear = () => {
    setSelectedDistricts([]);
    setSelectedTarget([]);
    setSelectedThemes([]);
    setSelectedYear([]);
    setSelectedProjectName('');
    dispatch(setFilter({yilFilter: [], targetFilter: [], themeFilter: [], isFilter: false}));
    dispatch(getAllProjectsForSearching());
  };

  //filtrele
  const handleFilter = async (e) => {
    e.preventDefault();

    const req = {
      projectName: selectedProjectName,
      projectYear: selectedYear,
      projectTargetGroups: selectedTarget,
      projectThemes: selectedThemes,
      sustainableFieldsOfActivity: [''],
    };

    dispatch(getAllProjectsForSearching(req));
    dispatch(setFilter({yilFilter: selectedYear, targetFilter: selectedTarget, themeFilter: selectedThemes}));

    // //reduxtan gelen cevabı görmek için yazıldı >>
    // const resp = dispatch(getAllProjectsForSearching(req));
    // resp.then((resp) => {
    //   console.log('res:', resp?.payload);
    // });
    // //<<<
  };

  //proje adı search input handler
  const projectNameChange = (value) => {
    setSelectedProjectName(value);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item>
          <Accordion.Header>Proje Adı</Accordion.Header>
          <Accordion.Body>
            <Form.Control
              type="text"
              name="projectName"
              placeholder="Lütfen Proje Adı Yazınız..."
              value={selectedProjectName}
              onChange={(e) => projectNameChange(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">Lütfen Boş Bırakmayınız.</Form.Control.Feedback>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item>
          <Accordion.Header>Yıllar</Accordion.Header>
          <Accordion.Body>
            {item.map((item) => (
              <div className="form-check" key={item}>
                <label htmlFor={item} className="d-flex align-items-center justify-content-between form-check-label">
                  <input
                    type="checkbox"
                    id={item}
                    className="form-check-input"
                    onChange={(e) => handleYearCheckboxChange(e.target.value)}
                    value={item}
                    checked={selectedYear?.includes(item?.toString())}
                  />
                  <span className="ms-2 fw-medium">{item}</span>
                  <span className="ms-auto text-secondary fw-regular" style={{fontSize: '14px', color: '#666'}}></span>
                </label>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Hizmet Alanı</Accordion.Header>
          <Accordion.Body>
            {themesData &&
              themesData.map((theme) => (
                <div className="form-check" key={theme.id}>
                  <label htmlFor={theme.name} className="d-flex align-items-center justify-content-between form-check-label">
                    <input
                      type="checkbox"
                      id={theme.name}
                      className="form-check-input"
                      onChange={() => handleThemesCheckboxChange(theme.id.toString())}
                      checked={selectedThemes?.includes(theme?.id?.toString())}
                      value={theme.id}
                    />
                    <span className="ms-2 fw-medium">{theme.name}</span>
                    <span className="ms-auto text-secondary fw-regular" style={{fontSize: '14px', color: '#666'}}>
                      {/* ({theme.projectCount}) */}
                    </span>
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>İlçeler</Accordion.Header>
          <Accordion.Body>
            {districtsData &&
              districtsData.map((districts) => (
                <div className="form-check" key={districts.id}>
                  <label htmlFor={districts.name} className="d-flex align-items-center justify-content-between form-check-label">
                    <input
                      type="checkbox"
                      id={districts.name}
                      className="form-check-input"
                      onChange={() => handleDistrictCheckboxChange(districts.id)}
                      checked={selectedDistricts.includes(districts.id)}
                      value={districts.id}
                    />
                    <span className="ms-2 fw-medium">{districts.name}</span>
                    <span className="ms-auto text-secondary fw-regular" style={{fontSize: '14px', color: '#666'}}>
                      ({item.projectCount}) 
                    </span>
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Hedef Grup</Accordion.Header>
          <Accordion.Body>
            {targetsData &&
              targetsData.map((target) => (
                <div className="form-check" key={target.id}>
                  <label htmlFor={target.name} className="d-flex align-items-center justify-content-start form-check-label">
                    <input
                      type="checkbox"
                      id={target.name}
                      className="form-check-input"
                      value={target.id}
                      onChange={() => handleTargetCheckboxChange(target.id)}
                      checked={selectedTarget?.includes(target.id)}
                    />
                    <span className="ms-2 fw-medium">{target.name}</span>
                    {/* ... (other spans) */}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <button className={`filter-clear-btn text-nowrap `} onClick={handleAllClear}>
        Seçimleri Temizle
      </button>
      <button className={`filter-clear-btn text-nowrap mt-3`} onClick={handleFilter}>
        Filtrele
      </button>
    </>
  );
};

export default FilterAccordion;
