import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  filter: {
    yilFilter: [],
    themeFilter: [],
    targetFilter: [],
  },
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: initialState,
  reducers: {
    setFilter: (state, action) => {
      // console.log(action.payload)
      state.filter = action.payload;
    },
  },
});

export const {setFilter} = filterSlice.actions;

export default filterSlice.reducer;
