import { configureStore } from "@reduxjs/toolkit";
//reducers
import projects from "./slices/ProjectSlice";
import basket from "./slices/BasketSlice";
import user from "./slices/userSlice";
import targets  from "./slices/LooksupSlice";
import themes from "./slices/LooksupSlice";
import districts from "./slices/LooksupSlice";
import filter from "./slices/filterSlice";
import applicationTypes  from "./slices/LooksupSlice";
import subThemes  from "./slices/LooksupSlice";
import neighbourhoods  from "./slices/LooksupSlice";
import targetGroups  from "./slices/LooksupSlice";
 

import { loadState, saveState } from "./storage";

const persistedState = loadState();

const store = configureStore({
  reducer: {
    projects,
    basket,
    user,
    targets,
    themes,
    districts,
    filter,
    applicationTypes,
    subThemes,
    neighbourhoods,
    targetGroups
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
