import React, { useEffect } from "react";
//components
import CardCarousel from "../../components/CardCarousel";
import Banner from "../../components/Banner";
//package
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
//images
import leftArrow from "../../assets/images/news_detail/left_arrow.svg";
import newsDetailBg from "../../assets/images/news_detail/news_detail_bg.png";
import dateIcon from "../../assets/images/standart/date.svg";
import Gallery1 from "../../assets/images/news_detail/gallery_1.png";
import Gallery2 from "../../assets/images/news_detail/gallery_2.png";
import Gallery3 from "../../assets/images/news_detail/gallery_3.png";
import twitter from "../../assets/images/news_detail/twitter.png";
import whatsapp from "../../assets/images/news_detail/whatsapp.png";
import facebook from "../../assets/images/news_detail/facebook.png";
import instagram from "../../assets/images/news_detail/instagram.png";
import linkedin from "../../assets/images/news_detail/linkedin.png";
import googlePlus from "../../assets/images/news_detail/googlePlus.png";

//swiper

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import NewsCard from "../../components/NewsCard";

const NewsDetail = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Banner text="Haber Detayı" />
      <Container className="py-4">
        <Row>
          <Col md={12}>
            <Link to="/haberler" className="back-btn">
              <img src={leftArrow} alt="" /> Haberlere Geri Dön
            </Link>
            <hr className="page-hr" />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col
            md={10}
            className="d-flex align-items-center justify-content-center"
            style={{ height: "439px" }}
          >
            <img src={newsDetailBg} alt="" className="news-detail-img" />
          </Col>
          <Col md={9}>
            <h2 className="news-detail-title">
              İmamoğlu: İstanbullular İBB yönetiminde artık söz sahibi
            </h2>
            <div className="news-detail-date">
              <img src={dateIcon} alt="" />
              <span className="ms-2">25 Mart 2023</span>
            </div>
          </Col>
          <Col md={9} className="mt-4">
            <p className="news-detail-text">
              İstanbulluların doğrudan bütçe harcamaları ve öncelikleri ile
              ilgili kararlara aktif katılımını sağlayan “Katılımcı Bütçe”
              kapsamında seçilen projeler açıklandı. İstanbul halkının seçtiği
              projeler 2022 İBB bütçesi kapsamında devreye alınacak. İBB Başkanı
              Ekrem İmamoğlu “Bu kentte yöneticiyseniz, İstanbul’u da dinlemek
              zorundasınız. Dinlemiyorsanız, yola yanlış çıkmışsınız demektir”
              diyen İmamoğlu, “Kentin, milyonlarca vatandaşımızın etkin
              gruplarının, kuruluşlarının, odaların da katıldığı bir
              mekanizmayla yönetileceğine şimdiden söz veriyoruz” şeklinde
              konuştu.
            </p>
          </Col>
          <Col md={9}>
            <h4 className="news-detail-subtitle">Haberin Galerisi</h4>
            <Row style={{ rowGap: "25px" }}>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery1} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery2} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery3} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery1} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery1} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery2} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery3} alt="" />
                </div>
              </Col>
              <Col lg={3} md={4} sm={6}>
                <div className="news-gallery">
                  <img src={Gallery1} alt="" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={9}>
            <h4 className="news-detail-subtitle">Etiketler</h4>
            <div className="tags">
              <div className="tags-item text-nowrap">Kent Yönetimi</div>
              <div className="tags-item text-nowrap">Ekrem İmamoğlu</div>
              <div className="tags-item text-nowrap">İstanbul</div>
            </div>
          </Col>
          <Col md={9}>
            <h4 className="news-detail-subtitle">Haberi Paylaş</h4>
            <div className="social-links">
              <Link to="/">
                <img src={twitter} alt="" className="social-links-item" />
              </Link>
              <Link to="/">
                <img src={whatsapp} alt="" className="social-links-item" />
              </Link>
              <Link to="/">
                <img src={facebook} alt="" className="social-links-item" />
              </Link>
              <Link to="/">
                <img src={instagram} alt="" className="social-links-item" />
              </Link>
              <Link to="/">
                <img src={linkedin} alt="" className="social-links-item" />
              </Link>
              <Link to="/">
                <img src={googlePlus} alt="" className="social-links-item" />
              </Link>
            </div>
          </Col>
          <Col md={9} className="mt-5">
            <h4 className="news-detail-subtitle">Benzer Haberler</h4>
            {/* <CardCarousel /> */}
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination]}
              spaceBetween={20}
              slidesPerView={3}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              //responsive
              breakpoints={{
                992: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide>
                <NewsCard small />
              </SwiperSlide>
              <SwiperSlide>
                <NewsCard small />
              </SwiperSlide>
              <SwiperSlide>
                <NewsCard small />
              </SwiperSlide>
              <SwiperSlide>
                <NewsCard small />
              </SwiperSlide>
              <SwiperSlide>
                <NewsCard small />
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewsDetail;
