import { Spinner } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

const PageLoading = () => {
  return (
    <div className="d-flex align-items-center justify-content-center py-5">
      <Spinner animation="border" variant="dark" />
    </div>
  );
};

export const SkeletonLoading = (props) => {
  return (
    <ContentLoader speed={1} width={678} height={600}  backgroundColor="#f3f3f3" foregroundColor="#ecebeb" title='Yükleniyor...' {...props}>
    {/* //firs row */}
      <rect x="0" y="0" rx="10" ry="10" width="220" height="120" />
      <rect x="0" y="130" rx="4" ry="4" width="180" height="20" />
      <rect x="0" y="160" rx="2" ry="3" width="119" height="10" />

      <rect x="250" y="0" rx="10" ry="10" width="220" height="120" />
      <rect x="250" y="130" rx="4" ry="4" width="180" height="20" />
      <rect x="250" y="160" rx="2" ry="3" width="119" height="10" />

      <rect x="500" y="0" rx="10" ry="10" width="220" height="120" />
      <rect x="500" y="130" rx="4" ry="3" width="180" height="20" />
      <rect x="500" y="160" rx="2" ry="4" width="119" height="10" />
      {/* second row  */}

      <rect x="0" y="190" rx="10" ry="10" width="220" height="120" />
      <rect x="0" y="320" rx="4" ry="4" width="180" height="20" />
      <rect x="0" y="350" rx="2" ry="3" width="119" height="10" />

      <rect x="250" y="190" rx="10" ry="10" width="220" height="120" />
      <rect x="250" y="320" rx="4" ry="4" width="180" height="20" />
      <rect x="250" y="350" rx="2" ry="3" width="119" height="10" />

      <rect x="500" y="190" rx="10" ry="10" width="220" height="120"/>
      <rect x="500" y="320" rx="2" ry="3" width="180" height="20" />
      <rect x="500" y="350" rx="4" ry="4" width="119" height="10" />
    </ContentLoader>
  );
};

export default PageLoading;
