import { createOAuthContext } from '@ibb/ui-components/lib/oauth/context'
import config from './config'

export const OAuth = createOAuthContext({
  name: config.OAUTH_APP_NAME,
  logout_url: config.LOGOUT_URL,
  token_url: config.OAUTH_TOKEN_URL,
  authorize_url: config.OAUTH_AUTHORIZE_URL,
  user_info_url: config.OAUTH_USERINFO_URL,
  client_id: config.OAUTH_CLIENT_ID,
  redirect_uri: config.OAUTH_REDIRECT_URI,
  scope: config.OAUTH_SCOPE,
  storage: "local",
});
