import React from "react";
import { Link } from "react-router-dom";
import dateIcon from "../../assets/images/standart/date.svg";
import cardImg from "../../assets/images/news/card_img.png";
import doubleRightAangle from "../../assets/images/news/aangle-double-right.svg";

const NewsCard = ({ small }) => {
  return (
    <div
      className={`news-card ${small ? "small shadow" : ""}`}
      style={{ borderColor: `${small ? "transparent" : ""}` }}
    >
      {small && <span className="bottom-line"></span>}
      <div
        className={`news-card-photo ${small ? "small" : ""}`}
        style={{ filter: `${small ? "grayScale(100%)" : ""}` }}
      >
        <img src={cardImg} alt="" className="news-card-photo-img" />
      </div>
      <div className={`news-card-content ${small ? "small p3" : ""}`}>
        <div className="news-card-content-date">
          <img src={dateIcon} alt="" />
          <span className="ms-2">25 Mart 2023</span>
        </div>
        <div className="news-card-content-header">
          <h4 className="news-card-content-header-title">
            Mama Otomatı projesi yakında hizmete giriyor
          </h4>
        </div>
        <hr className="news-card-hr" />
        <div className="news-card-content-body">
          <p className="news-card-content-body-text">
            Lorem ipsum enim ut sem viverra aliquet eget sit. Sem viverra
            aliquet eget sit. Neque volutpat ac tincidunt vitae semper quis
            lectus nulla.
          </p>
        </div>
        <div className={`news-card-content-footer ${small ? "small" : ""}`}>
          <Link to="/haberler/1" className="news-card-content-footer-link">
            Devamını Oku&nbsp;
            <img src={doubleRightAangle} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;

