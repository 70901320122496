import React from "react";
import "./Message.css";

export function Message({ title, description, children }) {
  return (
    <div className="message_page ff my-xl-5 my-lg-4 my-md-3 my-sm-2 my-1">
      <div className="jumbotron shadow-lg">
        <h1 className="mb-3">{title}</h1>
        <p className="lead">{description}</p>
        {children}
      </div>
    </div>
  );
}
