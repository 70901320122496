import { Fragment, useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Banner from "../../components/Banner";
import MyAccountCard from "../../components/MyAccountCard";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../components/AlertMessage";
import { handleSubmitAddCart } from "../../redux/slices/BasketSlice";
import { api } from "../../services/api";
import Swal from "sweetalert2";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const MyAccount = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const { submittedProjects, selectedProjects, votedProjects } = useSelector(
    (state) => state.basket
  );

  const { user } = useSelector((state) => state.user);

  const [checkedProjects, setCheckedProjects] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    if (Object.entries(user)?.length === 0) {
      history.push("/");
    }
  }, [user, history]);

  const handleSubmitAddCart = async () => {
    try {
      const { data } = await api.post(`/Basket/vote-to-basket`);
      if (data.message) {
        Swal.fire("Uyarı", data.message, "warning");
      } else {
        Swal.fire("Başarılı", "Sepet Onaylandı", "success");
        setTimeout(() => {
          window.location.href = `/hesabim?eventKey=vote`;
        }, 1000);
      }
    } catch (err) {
      Swal.fire("Hata", err, "error");
    }
  };

  const submitAddCart = () => {
    Swal.fire({
      title: "Sepeti onaylamak istediğinize emin misiniz?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitAddCart();
      }
    });
  };

  return (
    <>
      <Banner
        // text={`${query.get("eventKey") === "sent" ? "Projelerim" : "Hesabım"}`}
        text="Projelerim"
      />
      <Container className="my-5">
        <Row>
          <Col>
            <Tabs
              defaultActiveKey={`${
                query.get("eventKey") === "selected"
                  ? "selected"
                  : query.get("eventKey") === "vote"
                  ? "vote"
                  : "sent"
              }`}
              className="mb-3 justify-content-end"
            >
             {/* <Tab eventKey="sent" title="Önerdiğim Projeler">
                <div className="tab-item-header">
                  <h3 className="tab-header-title">Önerdiğim Projeler</h3>
                  <span className="tab-header-info">
                    {submittedProjects?.length}&nbsp;Proje
                  </span>
                </div>
                {submittedProjects === null ||
                submittedProjects?.length === 0 ? (
                  <AlertMessage
                    variant="warning"
                    message="Göndermiş olduğunuz proje bulunmamaktadır."
                  />
                ) : (
                  <MyAccountCard />
                )}
              </Tab>
              <Tab eventKey="selected" title="Sepetimdeki Projeler">
                <div className="tab-item-header">
                  <h3 className="tab-header-title">Sepetimdeki Projeler</h3>
                  <span className="tab-header-info">
                    {selectedProjects?.items?.length}&nbsp;Proje
                  </span>
                </div>
                {selectedProjects?.items?.length > 0 ? (
                  selectedProjects?.items?.map((item) => (
                    <Fragment key={item.id}>
                      <MyAccountCard
                        item={item}
                        selected
                        checkedProjects={checkedProjects}
                        setCheckedProjects={setCheckedProjects}
                      />
                    </Fragment>
                  ))
                ) : (
                  <AlertMessage
                    variant="warning"
                    message="Seçmiş olduğunuz proje bulunmamaktadır."
                  />
                )}
                {selectedProjects && selectedProjects?.items?.length > 0 && (
                  <div className="tab-footer col-md-10 mx-auto px-4">
                    <div className="row mb-2">
                      <div className="col-md-9">
                        <div className="tab-footer-result">
                          <div className="tab-footer-result-title">
                            Toplam Sepet Tutarı:
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="tab-footer-value">
                          {selectedProjects?.totalMoneyPoint} Puan
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="tab-footer-result">
                          <div className="tab-footer-result-title">
                            Sepet Onayı Sonrası Kalacak Bakiyeniz:
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="tab-footer-value">
                          {selectedProjects?.citizenRemainderMoneyPoint} Puan
                        </div>
                      </div>
                    </div>
                    <div className="error-message text-end">
                      {selectedProjects?.citizenRemainderMoneyPoint <= 0
                        ? "Puan Limitini Aştınız!"
                        : ""}
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="tab-footer-btn"
                        onClick={submitAddCart}
                      >
                        Sepeti Onayla
                      </button>
                    </div>
                  </div>
                )}
                      </Tab> */}
              <Tab eventKey="vote" title="Onayladığım Projeler">
                <div className="tab-item-header">
                  <h3 className="tab-header-title">Onayladığım Projeler</h3>
                  <span className="tab-header-info">
                    {votedProjects?.length}&nbsp;Proje
                  </span>
                </div>
                {votedProjects?.length > 0 ? (
                  votedProjects?.map((item, key) => (
                    <Fragment key={key}>
                      <MyAccountCard item={item} />
                    </Fragment>
                  ))
                ) : (
                  <AlertMessage
                    variant="warning"
                    message="Oy verdiğiniz proje bulunmamaktadır."
                  />
                )}
                <div className="col-md-10 mx-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <Link to="/" className="tab-footer-btn">
                      Anasayfa
                    </Link>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyAccount;
