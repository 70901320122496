import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { OAuth } from "../../services/oauth";
import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
} from "../../utility/fetchData";

export const getSubmittedProjects = createAsyncThunk(
  "/allprojectsbycitizen",
  async (_, thunkAPI) => {
    try {
      return await getDataAPI("Project/allprojectsbycitizen");
    } catch (error) {
      console.error(error);
      Swal.fire('Hata', error.errors.message, 'error');
    }
  }
);

export const getSelectedProjects = createAsyncThunk(
  "/selectedProjects",
  async (_, thunkAPI) => {
    try {
      return  await getDataAPI("Basket/citizen-basket");
    } catch (error) {
      // Swal.fire('Hata!', "Oturumunuzun süresi dolmuştur. Lütfen giriş yapınız..", 'error');
      OAuth.logout()
    }
  }
);

export const addProjectToBasket = createAsyncThunk(
  "/add-project-item-to-basket",
  async (id, thunkAPI) => {
    try {
      return await postDataAPI(
        `Basket/add-project-item-to-basket?toVoteProjectId=${id}`
      );
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteItemToBasket = createAsyncThunk(
  "/remove-project-item-to-basket",
  async (id, thunkAPI) => {
    try {
      return await deleteDataAPI(
        `Basket/remove-project-item-to-basket?toVoteProjectId=${id}`
      );
    } catch (error) {
      console.error(error);
    }
  }
);

{/*export const allVotedProjectByCitizen = createAsyncThunk(
  "allVotedProjectByCitizen",
  async (_, thunkAPI) => {
    try {
      return await getDataAPI("/Project/all-voted-project-bycitizen");
    } catch (err) {
      console.error(err);
    }
  }
); */}

export const handleSubmitAddCart = createAsyncThunk(
  "voteToBasket",
  async (_, thunkAPI) => {
    try {
      return await postDataAPI("/Basket/vote-to-basket");
    } catch (err) {
      console.error(err);
    }
  }
);

const initialState = {
  isLoading: false,
  submittedProjects: [],
  selectedProjects: [],
  //votedProjects: [],
  msg: "",
};

const BasketSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    submittedProjects: (state, action) => {
      state.submittedProjects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubmittedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubmittedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.submittedProjects = action.payload;
      })
      .addCase(getSubmittedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.submittedProjects = [];
      })
      .addCase(getSelectedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSelectedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProjects = action.payload;
      })
      .addCase(getSelectedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedProjects = [];
      })
      .addCase(addProjectToBasket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProjectToBasket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.submittedProjects = action.payload;
        //state.msg = action.payload;
      })
      .addCase(addProjectToBasket.rejected, (state, action) => {
        state.isLoading = false;
        state.submittedProjects = [];
      })
      .addCase(deleteItemToBasket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItemToBasket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProjects = action.payload;
      })
      .addCase(deleteItemToBasket.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedProjects = [];
      })
      .addCase(handleSubmitAddCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleSubmitAddCart.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(handleSubmitAddCart.rejected, (state) => {
        state.isLoading = false;
        {/*.addCase(allVotedProjectByCitizen.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(allVotedProjectByCitizen.fulfilled, (state, action) => {
          state.isLoading = false;
          state.votedProjects = action.payload;
        })
        .addCase(allVotedProjectByCitizen.rejected, (state) => {
          state.isLoading = false;
          state.votedProjects = [];
        })} */}
      });
  },
});

export const { submittedProjects } = BasketSlice.actions;
export default BasketSlice.reducer;
